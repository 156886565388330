import React, { useState } from 'react'

import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter/components/PhoneSegmentBuilder'

import { Button, ChipItem, ChipLabel, Spinner } from '@capturi/ui-components'
import {
  Box,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'

import { Trans, t } from '@lingui/macro'
import { MdClose } from 'react-icons/md'
import { useKeyTopicsFilterValues } from './useKeyTopicsFilterValues'

const KeyTopicsWrap: React.FC<{
  topics: string[]
  isSelected: (keyTopic: string) => boolean
  toggleSelection: (keyTopic: string) => void
}> = ({ topics, isSelected, toggleSelection }) => {
  return (
    <Wrap spacing={2} py={1} mb={1} w="100%">
      {topics.map((item: string) => (
        <WrapItem key={item}>
          <ChipItem
            backgroundColor={isSelected(item) ? 'gray.200' : 'gray.50'}
            borderWidth="1px"
            borderColor={isSelected(item) ? 'primary.500' : 'gray.200'}
            borderStyle="solid"
            cursor="pointer"
            variant="subtle"
            onClick={() => toggleSelection(item)}
          >
            <ChipLabel>{item}</ChipLabel>
          </ChipItem>
        </WrapItem>
      ))}
    </Wrap>
  )
}

export type KeyTopicSelectProps = FilterCriteriaComponentBaseProps<string[]>

export const KeyTopicSelect: React.FC<KeyTopicSelectProps> = (props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const {
    isLoading,
    isSelected,
    filteredKeyTopics,
    selectedKeyTopics,
    selectedSize,
    toggleSelection,
    resetSelection,
    selectAll,
  } = useKeyTopicsFilterValues(props.value ?? null, searchQuery)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleOk = () => {
    props.setValue?.(selectedKeyTopics)
    props.onClose?.()
  }

  return (
    <VStack gap={1}>
      <Box w="100%">
        <Box p={2} w="100%">
          <InputGroup size="sm">
            <Input
              autoFocus={true}
              placeholder={t`Search key topics...`}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {searchQuery && searchQuery.length > 0 && (
              <InputRightElement cursor="pointer">
                <Icon
                  fontSize="14px"
                  as={MdClose}
                  onClick={() => setSearchQuery('')}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </Box>
      </Box>
      <Box maxH={96} overflowY="auto" px={2} w="100%">
        {searchQuery === '' ? (
          <>
            <Text
              align="center"
              fontSize="sm"
              minH={5}
              mb={filteredKeyTopics.length > 0 ? 1 : 2}
            >
              <Trans>👆Start typing to find key topics...</Trans>
            </Text>
            {filteredKeyTopics.length > 0 && (
              <VStack align="start" gap={0}>
                <Divider borderColor="gray.300" mb={1} />
                <Text fontSize="xs" fontWeight="medium">
                  <Trans>Selected</Trans>
                </Text>
                <KeyTopicsWrap
                  topics={filteredKeyTopics}
                  isSelected={isSelected}
                  toggleSelection={toggleSelection}
                />
              </VStack>
            )}
          </>
        ) : isLoading ? (
          <Spinner delay={100} />
        ) : filteredKeyTopics.length > 0 ? (
          <VStack gap={2}>
            <Divider borderColor="gray.300" />
            <KeyTopicsWrap
              topics={filteredKeyTopics}
              isSelected={isSelected}
              toggleSelection={toggleSelection}
            />
          </VStack>
        ) : (
          <Text align="center" fontSize="sm" minH={5} mb={2}>
            <Trans>No results found</Trans>
          </Text>
        )}
      </Box>
      <Box boxShadow="0 -4px 8px -6px rgba(0,0,0,0.2)" p={2} w="100%">
        <HStack justifyContent="space-between" spacing={4}>
          <HStack spacing={2}>
            <Button
              isDisabled={
                selectAll === undefined || filteredKeyTopics.length === 0
              }
              onClick={selectAll}
              size="xs"
              variant="ghost"
            >
              <Trans>Select all</Trans>
            </Button>
            <Button
              size="xs"
              fontWeight="normal"
              variant="ghost"
              onClick={() => setSearchQuery('')}
              py={0}
            >
              <Trans>{selectedSize} selected</Trans>
            </Button>
          </HStack>
          <HStack spacing={1}>
            <Button onClick={resetSelection} size="xs" variant="ghost">
              <Trans>Reset</Trans>
            </Button>
            <Button onClick={handleOk} size="xs" primary variant="ghost">
              <Trans>OK</Trans>
            </Button>
          </HStack>
        </HStack>
      </Box>
    </VStack>
  )
}
