import analytics, { EventParams } from '@capturi/analytics'

export enum DashboardPublicEvent {
  SetDatePeriod = 'public-dashboard_SetDatePeriod',
  EnterFullscreen = 'public-dashboard_EnterFullscreen',
  LeaveFullscreen = 'public-dashboard_LeaveFullscreen',
  DashboardNotFound_Impression = 'public-dashboard_DashboardNotFound_Impression',
  FullscreenNudgeToast_Impression = 'public-dashboard_FullscreenNudgeToast_Impression',
  FullscreenNudgeToast_GoFullScreen = 'public-dashboard_FullscreenNudgeToast_GoFullScreen',
  TogglePlotOnlyWeekdays = 'public-dashboard_TogglePlotOnlyWeekdays',
}

export function logEvent(
  event: DashboardPublicEvent,
  data?: EventParams,
): void {
  analytics.event(event, data)
}
