import { useMemo } from 'react'

import request, { ResponseError } from '@capturi/request'

import { useSet } from '@capturi/react-utils'

import ufuzzy from '@leeoniya/ufuzzy'

import { useOrganization } from '@capturi/stores'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

type KeyTopicsResponse = { values: string[] }

const uf = new ufuzzy({ intraMode: 1, intraIns: 10 })

export const useKeyTopicsFilterValuesData = (): UseQueryResult<
  string[],
  ResponseError
> => {
  const { conversationLanguage } = useOrganization()
  const compare = Intl.Collator(conversationLanguage).compare

  return useQuery({
    queryKey: ['keyTopic', 'filter-values'],
    queryFn: () =>
      request.get<KeyTopicsResponse>(
        'organization/speech/filter-values/KeyTopic',
        {
          query: {
            'api-version': '3.3',
          },
        },
      ),
    select: (response) => response.values.sort(compare),
    staleTime: 60 * 60 * 24, // 1 day
  })
}
const useKeyTopicsOfflineSearch = (searchTerm: string) => {
  const { data, isLoading } = useKeyTopicsFilterValuesData()
  const keyTopics = data ?? []
  if (searchTerm === '') return { keyTopics, isLoading }

  const idxs = uf.filter(keyTopics, searchTerm)
  if (idxs === null || idxs.length === 0) return { keyTopics: [], isLoading }

  const filteredKeyTopics = idxs.map((idx) => keyTopics[idx])
  return { keyTopics: filteredKeyTopics, isLoading }
}

export const useKeyTopicsFilterValues = (
  initiallySelectedKeyTopics: string[] | null,
  searchTerm: string,
): {
  isLoading: boolean
  filteredKeyTopics: string[]
  selectedKeyTopics: string[]
  isSelected: (keyTopic: string) => boolean
  selectedSize: number
  toggleSelection: (keyTopic: string) => void
  resetSelection: () => void
  selectAll?: () => void
} => {
  const { isLoading, keyTopics: filteredKeyTopics } =
    useKeyTopicsOfflineSearch(searchTerm)

  const selected = useSet(initiallySelectedKeyTopics || [])

  return useMemo(() => {
    const toggleSelection = (keyTopic: string) => {
      selected.has(keyTopic)
        ? selected.delete(keyTopic)
        : selected.add(keyTopic)
    }

    const resetSelection = () => {
      selected.clear()
    }

    const selectAll = () => {
      filteredKeyTopics?.forEach((keyTopic) => {
        selected.add(keyTopic)
      })
    }

    const selectedKeyTopics = selected.toArray()

    if (searchTerm === '') {
      return {
        isLoading,
        filteredKeyTopics: selectedKeyTopics,
        selectedKeyTopics,
        isSelected: () => true,
        selectedSize: selected.size,
        toggleSelection,
        resetSelection,
        selectAll: undefined,
      }
    }
    return {
      isLoading,
      filteredKeyTopics,
      selectedKeyTopics,
      isSelected: (keyTopic: string) => selected.has(keyTopic),
      selectedSize: selected.size,
      toggleSelection,
      resetSelection,
      selectAll,
    }
  }, [isLoading, filteredKeyTopics, selected, searchTerm])
}
