import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { usePageTitle } from '@capturi/react-utils'
import { ContentPlaceholder } from '@capturi/ui-components'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { DashboardPublicEvent, logEvent } from '../utils/events'

const NotFoundPage: React.FC = () => {
  usePageTitle(t`Page not found`)
  React.useEffect(() => {
    logEvent(DashboardPublicEvent.DashboardNotFound_Impression)
  }, [])

  return (
    <ContentPlaceholder.Container mt={20}>
      <ContentPlaceholder.Image as={Icon_EmptyState} />
      <ContentPlaceholder.Heading>
        <Trans>404 - Page not found</Trans>
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Trans>
          The page you are looking for appears to have been moved, deleted or
          does not exist.
        </Trans>
      </ContentPlaceholder.Body>
    </ContentPlaceholder.Container>
  )
}

export default NotFoundPage
