import { AuthService } from '@capturi/auth'
import ky from 'ky'
import queryString from 'query-string'

import { parseJSON, stringifyJSON, updateDatesInUrl } from './json'
import { normalizeError } from './responseError'
import { Input, Options, RequestOptions } from './types'

const isDev = process.env.NODE_ENV === 'development'
const useProxyCapturiDevProxy =
  process.env.USE_CAPTURI_DEV_PROXY === 'true' || isDev

declare global {
  interface Window {
    env?: {
      apiUrl: string
    }
  }
}

const prodUrl = 'https://api.capturi.ai'

const prefixUrl = useProxyCapturiDevProxy ? '/api' : prodUrl

let baseDateDifference = 0

export const setAsDemoOrg = (baseDate: Date): void => {
  const startOfToday = new Date()
  startOfToday.setHours(0)
  startOfToday.setMinutes(0)
  startOfToday.setSeconds(0)
  startOfToday.setMilliseconds(0)

  baseDateDifference = startOfToday.getTime() - baseDate.getTime()
}

const isCapturiAnonymousRequest = (request: Request): boolean =>
  request.headers.get('capturi-anonymous') === 'true'

// `typeof ky` due to https://github.com/sindresorhus/ky/issues/256#issuecomment-722250903
const requestInstance: typeof ky = ky.create({
  credentials: 'include',
  timeout: 180_000, // 3 minutes
  prefixUrl,
  hooks: {
    beforeRequest: [
      async (request) => {
        if (
          !(
            AuthService.isRefreshUrl(request.url) ||
            isCapturiAnonymousRequest(request)
          )
        ) {
          await AuthService.validateAuthentication()

          if (useProxyCapturiDevProxy) {
            request.headers.set(
              'authorization',
              `Bearer ${AuthService.accessToken}`,
            )
            request.headers.set('refresh', `Bearer ${AuthService.refreshToken}`)
          }
        }
      },
    ],
    afterResponse: [
      async (
        request: Request,
        _options,
        response: Response,
        // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
      ): Promise<Response | void> => {
        /**
         * If response status is 401 unauthorized and it was not a refresh request
         * then refresh tokens and attempt request again
         */
        if (
          response.status === 401 &&
          !AuthService.isRefreshUrl(request.url) &&
          !isCapturiAnonymousRequest(request)
        ) {
          try {
            await AuthService.refreshAccessToken()

            return ky(request)
          } catch (_error) {
            location.reload() //Reload the page to show the login page
          }
        }
      },
    ],
  },
})

/**
 * Makes a request to our own API with authorization headers and credentials set
 */
const apiRequest = async (url: Input, options?: Options): Promise<Response> => {
  try {
    if (options?.query) {
      const u = queryString.stringifyUrl({
        url: url.toString(),
        query: options.query,
      })
      return await requestInstance(u, options)
    }

    return await requestInstance(url, options)
  } catch (error) {
    if (error instanceof ky.HTTPError) {
      const { status, statusText } = error.response
      let errorObject = null
      let errorText = ''
      try {
        errorText = await error.response.text()

        errorObject = JSON.parse(errorText)
      } catch (_) {
        errorObject = { title: errorText }
      }

      //The backend return different error errors types, so we try to normalize them
      throw normalizeError(statusText, status, errorObject, { url: url })
    }
    throw error
  }
}

/**
 * JSON request wrapper for own API, see request() above.
 */
const jsonApiRequest = async <T>(url: Input, options?: Options): Promise<T> => {
  const { json, headers = {}, ...rest } = options || {}

  //to handle dates in the url params for the demo org, we parses them and subs the time from basedate
  const newUrl = updateDatesInUrl(url.toString(), baseDateDifference)

  const response = await apiRequest(newUrl, {
    ...rest,
    body: json ? stringifyJSON(json, baseDateDifference) : rest.body,
    headers: json
      ? {
          'content-type': 'application/json; charset=utf-8',
          ...headers,
        }
      : headers,
  })
  return parseJSON<T>(response, baseDateDifference)
}

/**
 * Helper method to generate a specific HTTP method request function
 */
function createAPIRequestMethod(method: Options['method']) {
  return async function req<T>(url: Input, options?: Options): Promise<T> {
    return await jsonApiRequest(url, {
      ...options,
      method,
    })
  }
}

interface DefaultRequestInstance {
  <T>(req: RequestOptions): Promise<T>
  get: <T>(url: Input, options?: Options) => Promise<T>
  post: <T>(url: Input, options?: Options) => Promise<T>
  put: <T>(url: Input, options?: Options) => Promise<T>
  patch: <T>(url: Input, options?: Options) => Promise<T>
  delete: <T>(url: Input, options?: Options) => Promise<T>
}

const request: DefaultRequestInstance = (req) => {
  const { url, ...options } = req
  return jsonApiRequest(url, options)
}
request.get = createAPIRequestMethod('get')
request.post = createAPIRequestMethod('post')
request.put = createAPIRequestMethod('put')
request.patch = createAPIRequestMethod('patch')
request.delete = createAPIRequestMethod('delete')

export { ky as client, apiRequest }
export default request
