import { FilterPeriodProviderProps } from '@capturi/filters'
import qs from 'query-string'
import * as React from 'react'

export function withPeriodSyncToSearchParams<
  P extends FilterPeriodProviderProps,
>(
  WrappedComponent: React.ComponentType<P>,
  searchParamKey = 'period',
): React.FC<P> {
  const EnhancedComponent: React.FC<P> = (props) => {
    const [period] = React.useState(() => {
      const searchParams = new URLSearchParams(window.location.search)
      return searchParams.get(searchParamKey) ?? undefined
    })
    return (
      <WrappedComponent
        initialPeriod={period}
        onPeriodChange={(definition) => {
          const url = qs.stringifyUrl({
            url: window.location.href,
            query: {
              [searchParamKey]: definition.name,
            },
          })
          window.history.replaceState(null, '', url)
        }}
        {...props}
      />
    )
  }
  return EnhancedComponent
}
