import { ThemeProvider } from '@capturi/ui-theme'
import { Global } from '@emotion/react'
import React from 'react'

import globalStyles from './globalStyles'

const CapturiThemeProvider: React.FC<
  React.ComponentProps<typeof ThemeProvider>
> = ({ children, ...props }) => {
  return (
    <ThemeProvider {...props}>
      <Global styles={globalStyles} />
      {children}
    </ThemeProvider>
  )
}

export default CapturiThemeProvider
