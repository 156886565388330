import { RepeatCall } from '@capturi/api-filters'
import { useOrganizationSettings } from '@capturi/api-organization'
import { Button } from '@capturi/ui-components'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'

import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter'
import { MIN_REPEAT_CALL_DISTANCE } from '../constants'
import RepeatCallsCriteria from './RepeatCallsCriteria'
import { RepeatCallsRadioGroup } from './RepeatCallsRadioGroup'
import { secondsToTimeValue, timeValueToSeconds } from './utils'

export type RepeatCallProps = FilterCriteriaComponentBaseProps<RepeatCall>

const getLocalizedRepeatCallType = (type: RepeatCall['type']): string => {
  switch (type) {
    case 'Causing':
      return t`Trigger calls should be within`
    case 'Repeat':
      return t`Repeat call should be within`
    case 'None':
      return t`No subsequent or prior calls within`
  }
}

type RepeatCallsState = {
  type: RepeatCall['type']
  value: number
  timeUnit: 'days' | 'hours'
  min: number
}

export const RepeatCalls: React.FC<RepeatCallProps> = (props) => {
  const { data } = useOrganizationSettings()
  const maxValue = props.value?.value ?? data?.repeatCall.maxDistance
  const convertedValue = secondsToTimeValue(maxValue ?? 86400) // defaults to 1 day
  const [state, setState] = useState<RepeatCallsState>({
    type: props.value?.type ?? 'Repeat',
    value: convertedValue.value,
    timeUnit: convertedValue.timeUnit,
    //This value is strictly set to 15 minutes (in seconds for api), until the value can be set properly in the org config (see figma)
    min: data?.repeatCall.minDistance ?? MIN_REPEAT_CALL_DISTANCE,
  })

  const handleOk = (): void => {
    props.setValue?.({
      type: state.type,
      value: timeValueToSeconds(state.value, state.timeUnit),
      min: data?.repeatCall.minDistance ?? MIN_REPEAT_CALL_DISTANCE,
    })
    props.onClose?.()
  }

  const onCriteriaChange = (value: number, timeUnit: 'days' | 'hours') => {
    setState({ ...state, value: value, timeUnit: timeUnit })
  }

  const onComparatorChange = (value: RepeatCall['type']) => {
    setState({ ...state, type: value })
  }
  return (
    <Flex flexDir="column">
      <VStack p={4} gap={4} alignItems="flex-start">
        <Text fontWeight="medium">
          <Trans>Repeat calls</Trans>
        </Text>
        <RepeatCallsRadioGroup
          value={state.type}
          onChange={onComparatorChange}
        />
        <RepeatCallsCriteria
          onChange={onCriteriaChange}
          value={{ value: state.value, timeUnit: state.timeUnit }}
          title={getLocalizedRepeatCallType(state.type)}
        />
      </VStack>
      <HStack
        pt={3}
        mb={3}
        mt={4}
        boxShadow="0px -4px 8px -6px rgba(0, 0, 0, 0.20)"
        justify="flex-end"
      >
        <Button onClick={handleOk} size="xs" primary variant="ghost">
          <Trans>OK</Trans>
        </Button>
      </HStack>
    </Flex>
  )
}
