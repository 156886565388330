import { DefaultFallbackComponent, FallbackProps } from '@capturi/react-utils'
import { ResponseError } from '@capturi/request'
import React from 'react'

import NotFoundPage from './NotFoundPage'

const HttpErrorBoundaryFallback: React.FC<FallbackProps> = (props) => {
  const { error } = props
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  let status: number | undefined = (error as any)?.status
  if (error instanceof ResponseError) {
    status = error.statusCode
  }
  if ([403, 404].includes(status ?? 0)) {
    return <NotFoundPage />
  }
  return <DefaultFallbackComponent {...props} />
}

export default HttpErrorBoundaryFallback
