import {
  Box,
  Kbd as ChakraKbd,
  KbdProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const Kbd: React.FC<KbdProps> = (props) => (
  <ChakraKbd fontSize="1.1em" {...props} />
)

const ScaleFontSizeTooltipContent: React.FC = () => {
  const isMac = navigator.appVersion.indexOf('Mac') !== -1
  const cmdKey = isMac ? '⌘' : 'ctrl'
  return (
    <Box>
      <Text fontSize="1.15em" fontWeight="medium" mb={2}>
        <Trans>Increase or decrease size of text and widgets</Trans>
      </Text>
      <Text>
        <Trans>
          You can increase or decrease the size of the text and widgets by using
          the following shortcuts on your keyboard
        </Trans>
      </Text>
      <Stack spacing={4} mt={4}>
        <Box>
          <Text fontWeight="medium" mb={1}>
            <Trans>Increase size</Trans>
          </Text>
          <Kbd>{cmdKey}</Kbd> + <Kbd>+</Kbd>
        </Box>
        <Box>
          <Text fontWeight="medium" mb={1}>
            <Trans>Decrease size</Trans>
          </Text>
          <Kbd>{cmdKey}</Kbd> + <Kbd>-</Kbd>
        </Box>
      </Stack>
    </Box>
  )
}

const ScaleFontSizeTooltip: React.FC<
  Omit<PopoverProps, 'children'> & { children?: React.ReactNode }
> = ({ children }) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <ScaleFontSizeTooltipContent />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default ScaleFontSizeTooltip
