import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import noop from 'lodash/noop'
import React, { useState } from 'react'

import {
  DEFAULT_LANGUAGE,
  LanguageOption,
  findLanguage,
  isSupportedLanguage,
  supportedLanguages,
} from './languages'
import usePreferredLanguage from './usePreferredLanguage'

type LanguageContextType = {
  language?: string
  setLanguage: (language: string) => void
  languageOptions: LanguageOption[]
  findLanguage: (language: string) => LanguageOption | undefined
}

const LanguageContext = React.createContext<LanguageContextType>({
  language: undefined,
  setLanguage: noop,
  languageOptions: [],
  findLanguage: () => undefined,
})

export const useLanguage = (): LanguageContextType =>
  React.useContext(LanguageContext)

export const LanguageProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [hasLoadedLanguage, setHasLoadedLanguage] = useState<boolean>(false)
  const [language, setLanguage] = usePreferredLanguage()

  React.useEffect(() => {
    async function loadMessages(language: string): Promise<void> {
      try {
        setHasLoadedLanguage(false)
        const languageCode = language.substring(0, 2)
        const catalog = await import(`../locales/${languageCode}/messages.mjs`)
        i18n.load(languageCode, catalog.messages)
        i18n.activate(languageCode)
        // Used as a flag to know when we are ready to render
        setHasLoadedLanguage(true)
      } catch (e) {
        console.error(e) // eslint-disable-line no-console
        // Locale messages not loaded fallback
        i18n.activate('en')
        setHasLoadedLanguage(true)
      }
    }

    // Load language data
    if (isSupportedLanguage(language)) {
      loadMessages(language)
    } else {
      loadMessages(DEFAULT_LANGUAGE)
    }
  }, [language])

  const context = React.useMemo<LanguageContextType>(() => {
    return {
      language,
      setLanguage,
      languageOptions: supportedLanguages,
      findLanguage,
    }
  }, [language, setLanguage])

  // Skip rendering when catalog isn't loaded.
  if (!hasLoadedLanguage) return null

  return (
    <LanguageContext.Provider value={context}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </LanguageContext.Provider>
  )
}
