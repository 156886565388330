import { logEvent } from '@capturi/dashboard'
import { Button, useToast } from '@capturi/ui-components'
import { Flex, Text, ToastId } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { I18nProvider } from '@lingui/react'
import React, { useRef } from 'react'
import { useTimeoutFn } from 'react-use'
import screenfull from 'screenfull'

import { DashboardPublicEvent } from '../utils/events'

const GoFullScreenToast: React.FC<{ onGoFullScreen: () => void }> = ({
  onGoFullScreen,
}) => {
  return (
    <I18nProvider i18n={i18n}>
      <Flex justify="center">
        <Text lineHeight={1.5}>
          <Trans>
            Switch to fullscreen mode for a better dashboard experience?
          </Trans>
        </Text>
        <Button primary onClick={() => onGoFullScreen()}>
          <Trans>Yes, go full screen</Trans>
        </Button>
      </Flex>
    </I18nProvider>
  )
}

type UseFullscreenNudgeOptions = {
  isFullscreen: boolean
  delay: number
  onGoFullscreen: () => void
}

export default function useFullscreenNudge({
  isFullscreen,
  delay,
  onGoFullscreen,
}: UseFullscreenNudgeOptions): () => void {
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()

  function closeToast(): void {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  useTimeoutFn(() => {
    if (isFullscreen || !screenfull.isEnabled) {
      return
    }
    toastIdRef.current = toast({
      title: t`View in fullscreen mode`,
      description: (
        <GoFullScreenToast
          onGoFullScreen={() => {
            onGoFullscreen()
            closeToast()
            logEvent(DashboardPublicEvent.FullscreenNudgeToast_GoFullScreen, {
              width: window.screen.availWidth,
              height: window.screen.availHeight,
            })
          }}
        />
      ),
      duration: 15000,
      variant: 'subtle',
    })
  }, delay)

  return closeToast
}
