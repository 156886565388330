import analytics, { init } from '@capturi/analytics'

//!! The posthog apikey is also in /apps/app/src/analytics/keys.ts
//Remember to update it there as well!!
//Somebody should fix that.
const POSTHOG_API_KEY = 'phc_P89YgP7pp9TbmGnMB2s9G06vhaI0PWD4kBMvHot7OEu'
export function initAnalytics(): void {
  init({
    posthog: {
      token: POSTHOG_API_KEY,
      config: {
        disable_session_recording: true,
        api_host: 'https://posthog.capturi.ai',
        request_batching: true,
        autocapture: false,
        capture_pageview: false,
      },
    },
    debug: process.env.NODE_ENV === 'development',
  })
}

export default analytics
